import { Scope } from "@kamae-apps/shared/Types/Scope"

export function scopeToString(scope: Scope) {
  switch (scope) {
    case Scope.COMPANY_ADMIN:
      return "Admin Entreprise"
    case Scope.USER:
      return "User"
    case Scope.SUPER_ADMIN:
      return "Admin Kamaé"
  }
}

export function stringToScope(str: string): Scope {
  return (Scope[str as keyof typeof Scope] as Scope) || Scope.USER
}
