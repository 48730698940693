import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import LazyImportPlugin from "./lazyImport"

type Namespaces = ["app"]

const langs = ["fr"]
const namespaces: Namespaces = ["app"]

i18n
  .use(LanguageDetector)
  .use(LazyImportPlugin)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "lang",
      convertDetectedLanguage: lng => lng.split("-")[0],
    },
    supportedLngs: langs,
    ns: namespaces,
    defaultNS: "app",
    load: "currentOnly",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: true },
    missingKeyHandler: function (lng, ns, key) {
      console.error(ns + " " + key + " " + lng)
    },
  })

export default i18n
