import PrimaryButton from "@kamae-apps/shared/old/Component/Button/PrimaryButton"
import CalendarInput from "@kamae-apps/shared/old/Component/Input/CalendarInput"
import Slider from "@kamae-apps/shared/old/Component/Input/Slider"
import React, { FC } from "react"
import { Calendar } from "react-feather"
import { ElearningAdmin } from "../../../../Types/ELearning"
import { CategoryKey } from "./ElearningCategory"

type ElearningsListProps = {
  elearnings: ElearningAdmin[]
  categoryKey: CategoryKey
  check: boolean[]
  setCheck: (value: boolean[]) => void
  date: Date[]
  setDate: (value: Date[]) => void
  width: number
  setDisplayCalendar: (value: boolean) => void
  setElearningUpdateIndex: (value: number) => void
}

export const ElearningsList: FC<ElearningsListProps> = ({
  elearnings,
  categoryKey,
  check,
  setCheck,
  date,
  setDate,
  width,
  setElearningUpdateIndex,
  setDisplayCalendar,
}) => {
  return (
    <>
      {elearnings.map((elearning, i) => {
        if (elearning.category !== categoryKey) {
          return (
            <div
              key={i}
              className={"hidden"}
            ></div>
          )
        }
        const comment = elearning.comment == null ? "" : `(${elearning.comment})`
        return (
          <div
            className={
              "flex w-2/3 shrink-0 flex-col justify-center gap-3 rounded-xl bg-white p-3 drop-shadow-md hover:drop-shadow-lg"
            }
            key={i}
          >
            <div className={"flex items-center justify-start gap-3"}>
              <Slider
                value={elearning.id}
                checked={check[i] ?? false}
                id={"elearning" + elearning.id}
                onChange={() => {
                  setCheck(check.map((v, index) => (i === index ? !v : v)))
                }}
                className={"m-1"}
              />
              <label
                htmlFor={"elearning" + elearning.id}
                className={"w-full"}
              >
                <p className={"mr-2"}>
                  {elearning.title}
                  <wbr />
                  <span className={"pl-2 text-sm text-gray-400"}>{comment}</span>
                </p>
              </label>
            </div>
            <div className={"flex w-full justify-between"}>
              <CalendarInput
                className={"mx-0 !w-3/4"}
                value={date[i] ?? new Date()}
                setValue={value => setDate(date.map((v, index) => (i === index ? value : v)))}
                place={
                  width > 1500 ? (i < 3 ? "RIGHT_BOTTOM" : i < 6 ? "RIGHT" : "RIGHT_TOP") : i < 7 ? "BOTTOM" : "TOP"
                }
                displayCalendarButton={false}
              />
              <PrimaryButton
                className={"!m-1 !p-1"}
                onClick={() => {
                  setElearningUpdateIndex(i)
                  setDisplayCalendar(true)
                }}
              >
                <Calendar className={"no-fill"} />
              </PrimaryButton>
            </div>
          </div>
        )
      })}
    </>
  )
}
