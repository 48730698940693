import clsx from "clsx"
import { FC } from "react"
import { CompanyData } from "../../../../../Companies"
import { useSCIMConnection } from "./useSCIMConnection"

type SCIMConnectionProps = {
  company: CompanyData
}

export const SCIMConnection: FC<SCIMConnectionProps> = ({ company }) => {
  const { isScimEnable } = useSCIMConnection(company)

  return (
    <>
      <div className={"border-1 border-gray-300"} />
      <div className={"flex w-1/3 flex-col justify-start gap-2"}>
        <h1 className={"text-center text-xl"}>Configuration SCIM</h1>
        <div className={"flex justify-center"}>
          <p className={"my-3"}>
            État de la connexion :{" "}
            <span className={clsx(isScimEnable ? "text-emerald-500" : "text-rose-500")}>
              {isScimEnable ? "Activé" : "Désactivé ou pas encore reçu d'évènements"}
            </span>
          </p>
        </div>
      </div>
    </>
  )
}
