import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany"
import { NewTeam } from "@kamae-apps/shared/Types/Team/TTeam"
import CancelButton from "@kamae-apps/shared/old/Component/Button/CancelButton"
import ValidateButton from "@kamae-apps/shared/old/Component/Button/ValidateButton"
import Input from "@kamae-apps/shared/old/Component/Input/Input"
import Select from "@kamae-apps/shared/old/Component/Input/Select"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { errorToast, successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest, clone } from "@kamae-apps/shared/utils"
import React, { useEffect, useState } from "react"
import { CompanyData } from "../Companies/Companies"
import { TeamData, emptyTeamData } from "./Teams"

interface TeamAddUpdateModalProps {
  type: "add" | "update"
  modalDisplay: boolean
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
  teamData: TeamData
  setTeamData: React.Dispatch<React.SetStateAction<TeamData>>
  company: TCompany[] | CompanyData[]
}

export default function TeamAddUpdateModal(props: TeamAddUpdateModalProps) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [valid, setValid] = useState(false)
  useEffect(() => {
    setValid(props.teamData.name !== "" && props.teamData.companyId !== -1)
  }, [props.teamData, props.modalDisplay])
  useEffect(() => {
    if (props.teamData.companyId === -1 && props.company.length === 1) {
      const tmp = clone(props.teamData)
      tmp.companyId = props.company[0].id
      props.setTeamData(tmp)
    }
  }, [props])
  return (
    <Modal
      title={props.type === "add" ? "Ajouter une équipe" : "Modifier une équipe"}
      display={props.modalDisplay}
      setDisplay={props.setModalDisplay}
      width={"w-1/2"}
      overflow
    >
      <form
        className={"flex w-full flex-col items-center gap-6"}
        onSubmit={e => e.preventDefault()}
      >
        <Input
          disabled={loading}
          placeholder={"Nom"}
          value={props.teamData.name}
          onChange={e => {
            const tmp = Object.assign({}, props.teamData)
            tmp.name = e.target.value
            props.setTeamData(tmp)
          }}
        />
        <Select
          disabled={loading}
          searchable
          defaultValue={props.teamData.companyId}
          onChange={e => {
            const tmp = Object.assign({}, props.teamData)
            tmp.companyId = parseInt(e.target.value)
            props.setTeamData(tmp)
          }}
          options={props.company.map(v => ({ value: v.id, name: v.name }))}
          placeholder={"Entreprise"}
        />
        <div className={"flex"}>
          <ValidateButton
            disabled={!valid}
            validate={"Valider"}
            type={"submit"}
            onClick={() => {
              if (props.type === "add") {
                setLoading(true)
                const data: NewTeam = {
                  name: props.teamData.name,
                }
                apiRequest("/teams/company/" + props.teamData.companyId, {
                  method: "POST",
                  body: data,
                })
                  .then(() => {
                    setLoading(false)
                    props.setModalDisplay(false)
                    toast.addToast(successToast("Équipes ajouté"))
                  })
                  .catch(() => {
                    setLoading(false)
                    toast.addToast(errorToast("Erreur lors de l'ajout de l'équipe"))
                  })
              } else {
                setLoading(true)
                const data = {
                  name: props.teamData.name,
                }
                apiRequest("/teams/" + props.teamData.id, {
                  method: "PUT",
                  body: data,
                })
                  .then(() => {
                    setLoading(false)
                    props.setModalDisplay(false)
                    toast.addToast(successToast("Équipe mis à jour"))
                  })
                  .catch(() => {
                    setLoading(false)
                    toast.addToast(errorToast("Erreur lors de la mis à jour de l'équipe"))
                  })
              }
            }}
            spinning={loading}
          />
          <CancelButton
            cancel={"Annuler"}
            onClick={() => {
              props.setModalDisplay(false)
              props.setTeamData(emptyTeamData)
            }}
          />
        </div>
      </form>
    </Modal>
  )
}
