import React, { createContext, ReactElement, useContext, useState } from "react"

interface TAPIStatusContext {
  code: number
  message: string
}

const initContext: TAPIStatusContext = {
  code: 0,
  message: "",
}

export let externalSetApiStatus: React.Dispatch<React.SetStateAction<TAPIStatusContext>> = () => {}

const useApiStatusState = () => useState(initContext)

const ApiStatusContext = createContext<ReturnType<typeof useApiStatusState> | null>(null)

export function ApiStatusStateProvider(props: { children: ReactElement }) {
  const [apiStatus, setApiStatus] = useApiStatusState()
  externalSetApiStatus = setApiStatus
  return <ApiStatusContext.Provider value={[apiStatus, setApiStatus]}>{props.children}</ApiStatusContext.Provider>
}

export const useApiStatusSharedState = () => {
  const value = useContext(ApiStatusContext)
  if (value === null) {
    throw new Error("No provider for Api Status State")
  }
  return value
}
