import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest"
import useReload, { DoReload } from "@kamae-apps/shared/Hooks/useReload"
import { APIError } from "@kamae-apps/shared/Types/API"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"
import TUser from "@kamae-apps/shared/Types/TUser"
import Button from "@kamae-apps/shared/old/Component/Button/Button"
import CancelButton from "@kamae-apps/shared/old/Component/Button/CancelButton"
import DangerButton from "@kamae-apps/shared/old/Component/Button/DangerButton"
import ValidateButton from "@kamae-apps/shared/old/Component/Button/ValidateButton"
import CheckBox from "@kamae-apps/shared/old/Component/Input/CheckBox"
import FileInput from "@kamae-apps/shared/old/Component/Input/FileInput"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import ScrollText from "@kamae-apps/shared/old/Component/ScrollText/ScrollText"
import { Filter } from "@kamae-apps/shared/old/Component/Table/Filters"
import Table, { Column, SortedOrder } from "@kamae-apps/shared/old/Component/Table/Table"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { errorToast, successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest, apiRequestRaw } from "@kamae-apps/shared/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Edit, RefreshCw, Trash } from "react-feather"
import { scopeToString } from "../../../../Types/Scope"
import { statusToString } from "../../../../utils"
import config from "../../../../variable"
import UserDeleteModal from "../../../User/DeleteModal"
import { TUserToUserData, UserData, emptyUserData } from "../../../User/User"
import { UserAddUpdateModal } from "../../../User/UserAddUpdateModal/UserAddUpdateModal"
import { CompanyData } from "../../Companies"

interface CompanyUsersProps {
  company: CompanyData
}

export default function CompanyUsers(props: CompanyUsersProps) {
  const { company } = props

  const toast = useToast()
  const [csv, setCSV] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const [updateDisplay, setUpdateDisplay] = useState(false)
  const [deleteDisplay, setDeleteDisplay] = useState(false)
  const [deleteAllDisplay, setDeleteAllDisplay] = useState(false)
  const [type, setType] = useState<"add" | "edit">("add")
  const [userData, setUserData] = useState<UserData>(emptyUserData)

  const { reload, doReload } = useReload()

  const cols: Column[] = useMemo(() => {
    if (company.dir_sync_activated) {
      return [
        { title: "Nom", name: "name", size: "auto" },
        { title: "Prénom", name: "first_name", size: "auto" },
        { title: "Mail", name: "email", size: "auto" },
        { title: "Permission", name: "scope" },
        { title: "Statut", name: "status" },
        { title: "Pts", name: "score", size: "37px" },
        { title: "Cours", name: "elearning_count", size: "60px" },
        { title: "Love", name: "love", size: "50px" },
        { title: "Avatar", name: "avatar", sortable: false },
        { title: "Équipe", name: "team", size: "minmax(0, 150px)" },
      ]
    } else {
      return [
        { title: "Nom", name: "name", size: "auto" },
        { title: "Prénom", name: "first_name", size: "auto" },
        { title: "Mail", name: "email", size: "auto" },
        { title: "Permission", name: "scope" },
        { title: "Statut", name: "status" },
        { title: "Pts", name: "score", size: "37px" },
        { title: "Cours", name: "elearning_count", size: "60px" },
        { title: "Love", name: "love", size: "50px" },
        { title: "Avatar", name: "avatar", sortable: false },
        { title: "Équipe", name: "team", size: "minmax(0, 150px)" },
        { title: "Modif", name: "", sortable: false, size: "50px" },
        { title: "Suppr", name: "", sortable: false, size: "50px" },
      ]
    }
  }, [company.dir_sync_activated])

  const line = useCallback(
    (u: TUser) => {
      if (company.dir_sync_activated) {
        return [
          u.last_name,
          <p className={"mx-2"}>{u.first_name}</p>,
          u.email,
          scopeToString(u.scope!!),
          statusToString(u.status!!),
          u.global_score,
          <UserCountElearning id={u.id} />,
          <UserLove id={u.id} />,
          <img
            src={
              u.avatar !== undefined ? config.api + `/avatar/${u.avatar}/${getBeltColor(u.level)}` : "avatarLoading.png"
            }
            alt={"avatar"}
            className={"max-h-16"}
          />,
          <ScrollText>{u.team.name}</ScrollText>,
        ]
      } else {
        return [
          u.last_name,
          <p className={"mx-2"}>{u.first_name}</p>,
          u.email,
          scopeToString(u.scope!!),
          statusToString(u.status!!),
          u.global_score,
          <UserCountElearning id={u.id} />,
          <UserLove id={u.id} />,
          <img
            src={
              u.avatar !== undefined ? config.api + `/avatar/${u.avatar}/${getBeltColor(u.level)}` : "avatarLoading.png"
            }
            alt={"avatar"}
            className={"max-h-16"}
          />,
          <ScrollText>{u.team.name}</ScrollText>,
          <button
            onClick={() => {
              setType("edit")
              setUpdateDisplay(true)
              setUserData(TUserToUserData(u))
            }}
          >
            <Edit className={"no-fill text-gray-400"} />
          </button>,
          <button
            onClick={() => {
              setDeleteDisplay(true)
              setUserData(TUserToUserData(u))
            }}
            className={"self-center justify-self-center"}
          >
            {u.status !== -1 ? (
              <Trash className={"no-fill text-gray-400"} />
            ) : (
              <RefreshCw className={"no-fill text-gray-400"} />
            )}
          </button>,
        ]
      }
    },
    [company.dir_sync_activated]
  )

  const filters: Filter<unknown, unknown>[] = useMemo(() => {
    return [
      {
        name: "company",
        label: "Entreprise",
        values: [{ value: props.company.name, content: "" }],
        options: [{ value: props.company.name, content: "" }],
      },
    ]
  }, [props.company.name])

  const buttonAdd = useMemo(
    () => (
      <Button
        onClick={() => {
          setType("add")
          setUserData(emptyUserData)
          setUpdateDisplay(true)
        }}
        className={
          "border-primary text-primary hover:bg-primary grow whitespace-nowrap p-2 transition-colors hover:text-white"
        }
      >
        Ajouter un collaborateur
      </Button>
    ),
    []
  )

  const deleteAll = useMemo(() => {
    return (
      <DangerButton
        className={"whitespace-nowrap"}
        onClick={() => setDeleteAllDisplay(prev => !prev)}
      >
        Suppr tout les users
      </DangerButton>
    )
  }, [])

  useEffect(() => {
    doReload()
  }, [updateDisplay, deleteDisplay, doReload])

  return (
    <>
      <Table
        filter={filters}
        sortableColumn={cols}
        line={line}
        defaultSort={"id"}
        defaultOrder={SortedOrder.DESC}
        url={"/user/all"}
        previous={"Page précédente"}
        next={"Page suivante"}
        searchPlaceholder={"Rechercher"}
        actions={
          company.dir_sync_activated || (
            <div className={"flex"}>
              {buttonAdd}
              {deleteAll}
            </div>
          )
        }
        reload={reload}
        search
        navigation={
          company.dir_sync_activated || (
            <div className={"flex items-center"}>
              <FileInput
                className={"w-52"}
                placeHolder={"CSV"}
                accept={".csv"}
                value={csv}
                setValue={setCSV}
              />
              <ValidateButton
                validate={"Valider"}
                onClick={() => {
                  setLoading(true)
                  if (csv !== null) {
                    if (!csv.name.endsWith(".csv") || csv.type !== "text/csv") {
                      toast.addToast(errorToast("Le fichier doit être un fichier CSV valide"))
                    } else {
                      const fr = new FileReader()
                      fr.onloadend = function () {
                        apiRequestRaw(`/company/${company.id}/users/batch`, "text/csv", {
                          method: "POST",
                          body: fr.result ?? "",
                        })
                          .then(() => {
                            toast.addToast(successToast("Utilisateur ajouté"))
                            setLoading(false)
                          })
                          .catch(err => {
                            if (err instanceof APIError) {
                              if (err.code === 409) {
                                toast.addToast(errorToast("Un utilisateur est déjà existant"))
                              } else if (err.code === 400) {
                                toast.addToast(errorToast("CSV mal formé"))
                              } else {
                                toast.addToast(errorToast("Erreur lors de l'ajout de user"))
                              }
                            } else {
                              toast.addToast(errorToast("Erreur lors de l'ajout de user"))
                            }
                          })
                      }
                      fr.readAsArrayBuffer(csv)
                    }
                  }
                }}
                spinning={loading}
              />
            </div>
          )
        }
      />
      <UserAddUpdateModal
        type={type}
        modalDisplay={updateDisplay}
        setModalDisplay={setUpdateDisplay}
        userData={userData}
        setUserData={setUserData}
        companyList={company ? [company] : []}
      />
      <UserDeleteModal
        display={deleteDisplay}
        setDisplay={setDeleteDisplay}
        userData={userData}
      />
      <DeleteAllUsersModal
        display={deleteAllDisplay}
        setDisplay={setDeleteAllDisplay}
        company_id={props.company.id}
        doReload={doReload}
      />
    </>
  )
}

function UserCountElearning(props: { id: number }) {
  const n = useApiRequest<number>(`/user/${props.id}/elearning`, [props.id], {
    reset: true,
  })
  return <p>{n}</p>
}

function UserLove(props: { id: number }) {
  const n = useApiRequest<number>(`/user/${props.id}/love`, [props.id], {
    reset: true,
  })
  return <p>{n ?? "∅"}</p>
}

interface DeleteAllUsersModalProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  company_id: number
  doReload: DoReload
}

function DeleteAllUsersModal(props: DeleteAllUsersModalProps) {
  const [adminsIncluded, setAdminsIncluded] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <Modal
      title={"Supprimer tout les utilisateurs"}
      display={props.display}
      setDisplay={props.setDisplay}
    >
      <div className={"flex flex-col items-center"}>
        <p>
          Êtes vous sur de vouloir supprimer <span className={"font-bold text-red-600"}>TOUT</span> les utilisateurs de
          l'entreprise
        </p>
        <div className={"flex items-center gap-2"}>
          <CheckBox
            id={"checkbox-admin-included"}
            checked={adminsIncluded}
            onChange={() => setAdminsIncluded(prev => !prev)}
          />
          <label
            htmlFor={"checkbox-admin-included"}
            className={"cursor-pointer"}
          >
            Supprimer aussi les admins
          </label>
        </div>
        <div className={"flex"}>
          <CancelButton cancel={"Annuler"} />
          <DangerButton
            disabled={loading}
            onClick={() => {
              if (confirm) {
                setLoading(true)
                apiRequest(`/company/${props.company_id}/users/delete${adminsIncluded ? "?admin=true" : ""}`, {
                  method: "DELETE",
                }).then(() => {
                  setLoading(false)
                  props.setDisplay(false)
                  props.doReload()
                })
              } else {
                setConfirm(true)
                setTimeout(() => {
                  setConfirm(false)
                }, 2000)
              }
            }}
          >
            {(confirm && "Vous êtes sur ?") || "Supprimer"}
          </DangerButton>
        </div>
      </div>
    </Modal>
  )
}
