import clsx from "clsx"
import { CSSProperties } from "react"

interface DividerProps {
  className?: string
  style?: CSSProperties
}

export default function Divider(props: DividerProps) {
  const css = clsx(props.className, "border-1 w-full bg-gray-100")
  return (
    <div
      className={css}
      style={props.style}
    />
  )
}
