import React from "react"

export default function Spinner() {
  return (
    <div
      className={
        "mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent transition-colors"
      }
    />
  )
}
