import { ConfirmDeleteButton } from "@kamae-apps/shared/old/Component/ConfirmDeleteButton/ConfirmDeleteButton"
import React, { FC } from "react"

type MailDomainProps = {
  value: string
  remove: (value: string) => void
}

export const MailDomainPills: FC<MailDomainProps> = ({ value, remove }) => {
  return (
    <div className={"border-1 border-primary bg-primary-50 flex rounded-full py-1 px-2"}>
      <p>{value}</p>
      <div className={"ml-2"}>
        <ConfirmDeleteButton deleteAction={() => remove(value)} />
      </div>
    </div>
  )
}
