import { MouseEventHandler } from "react"
import PrimaryButton from "./PrimaryButton"

interface ValidateButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
  validate: string
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  spinning?: boolean
}

export default function ValidateButton(props: ValidateButtonProps) {
  return (
    <PrimaryButton
      disabled={props.disabled}
      type={props.type}
      spinning={props.spinning}
      onClick={props.onClick}
    >
      {props.validate}
    </PrimaryButton>
  )
}
