import { APIError } from "@kamae-apps/shared/Types/API"
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany"
import { Scope } from "@kamae-apps/shared/Types/Scope"
import CancelButton from "@kamae-apps/shared/old/Component/Button/CancelButton"
import ValidateButton from "@kamae-apps/shared/old/Component/Button/ValidateButton"
import Input from "@kamae-apps/shared/old/Component/Input/Input"
import Select, { OptionInterface } from "@kamae-apps/shared/old/Component/Input/Select"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import { errorToast, successToast, warningToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest, clone } from "@kamae-apps/shared/utils"
import React, { FC } from "react"
import { Repeat } from "react-feather"
import { NewUser } from "../../../Types/TUser"
import config from "../../../variable"
import { CompanyData } from "../../Companies/Companies"
import { UserData, emptyUserData } from "../User"
import { useUserAddUpdateModal } from "./useUserAddUpdateModal"

interface UserAddUpdateModalProps {
  type: "add" | "edit"
  modalDisplay: boolean
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>
  userData: UserData
  setUserData: React.Dispatch<React.SetStateAction<UserData>>
  companyList: TCompany[] | CompanyData[]
}

export const UserAddUpdateModal: FC<UserAddUpdateModalProps> = ({
  type,
  modalDisplay,
  setModalDisplay,
  userData,
  setUserData,
  companyList,
}) => {
  const { loading, setLoading, toast, valid, mailCss, avatar, teams } = useUserAddUpdateModal({
    type,
    modalDisplay,
    setModalDisplay,
    userData,
    setUserData,
    companyList: companyList,
  })
  return (
    <Modal
      title={type === "add" ? "Ajouter un collaborateur" : "Modifier un collaborateur"}
      display={modalDisplay}
      setDisplay={setModalDisplay}
      width={"w-1/2"}
      overflow
    >
      <form
        className={"flex w-full flex-col items-center gap-6"}
        onSubmit={e => e.preventDefault()}
      >
        <div className={"relative flex w-full items-center justify-center gap-6"}>
          <div className={"w-6"} />
          <div className={"flex w-3/5 flex-col items-center gap-6"}>
            <Input
              className={"!w-full"}
              autoFocus
              disabled={loading}
              placeholder={"Prénom"}
              value={userData.first_name}
              onChange={e => {
                const tmp = Object.assign({}, userData)
                tmp.first_name = e.target.value
                setUserData(tmp)
              }}
            />
            <Input
              className={"!w-full"}
              disabled={loading}
              placeholder={"Nom"}
              value={userData.last_name}
              onChange={e => {
                const tmp = Object.assign({}, userData)
                tmp.last_name = e.target.value
                setUserData(tmp)
              }}
            />
          </div>
          <Repeat
            className={"no-fill w-6 rotate-90"}
            onClick={() => {
              const tmp = clone(userData)
              if (tmp.last_name.length > 0) {
                tmp.first_name =
                  userData.last_name[0].toLocaleUpperCase() + userData.last_name.toLocaleLowerCase().slice(1)
              }
              tmp.last_name = userData.first_name.toLocaleUpperCase()
              setUserData(tmp)
            }}
          />
        </div>
        <Input
          disabled={loading}
          placeholder={"Email"}
          className={mailCss}
          value={userData.email}
          onChange={e => {
            const tmp = Object.assign({}, userData)
            tmp.email = e.target.value
            if (
              type === "add" &&
              tmp.first_name.length === 0 &&
              tmp.last_name.length === 0 &&
              tmp.email.indexOf("@") !== -1
            ) {
              const names = tmp.email.split("@")[0].split(".")
              if (names.length === 2) {
                tmp.first_name = names[0][0].toLocaleUpperCase() + names[0].slice(1)
                tmp.last_name = names[1].toLocaleUpperCase()
              }
            }
            setUserData(tmp)
          }}
        />
        {type === "edit" && (
          <Select
            disabled={loading}
            searchable
            defaultValue={userData.avatar}
            className={"!z-50"}
            onChange={e => {
              const tmp = Object.assign({}, userData)
              tmp.avatar = e.target.value
              setUserData(tmp)
            }}
            options={
              avatar?.map(v => ({
                value: v,
                name: (
                  <div>
                    <img
                      src={config.api + "/avatar/" + v + "/white"}
                      alt={"avatar"}
                      className={"inline max-h-10"}
                    />
                    {v}
                  </div>
                ),
              })) ?? []
            }
            placeholder={"Avatar"}
          />
        )}
        <Select
          disabled={loading}
          defaultValue={userData.scope}
          className={"!z-40"}
          onChange={e => {
            const tmp = Object.assign({}, userData)
            tmp.scope = e.target.value as Scope
            setUserData(tmp)
          }}
          options={[
            { value: Scope.USER, name: "Utilisateur" },
            { value: Scope.COMPANY_ADMIN, name: "Admin entreprise" },
            { value: Scope.SUPER_ADMIN, name: "Admin Kamae" },
          ]}
          placeholder={"Scope"}
        />
        <Select
          disabled={loading}
          searchable={(companyList.length ?? 0) > 5}
          defaultValue={userData.companyId}
          className={"!z-30"}
          onChange={e => {
            const tmp = Object.assign({}, userData)
            tmp.companyId = parseInt(e.target.value)
            tmp.teamId = -1
            setUserData(tmp)
          }}
          options={companyList.map(v => ({ value: v.id, name: v.name }))}
          placeholder={"Entreprise"}
        />
        <Select
          disabled={loading || userData.companyId === -1}
          searchable={(teams?.length ?? 0) > 5}
          placeholder={"Équipes"}
          className={"!z-20"}
          defaultValue={userData.teamId}
          onChange={e => {
            const tmp = Object.assign({}, userData)
            tmp.teamId = parseInt(e.target.value)
            setUserData(tmp)
          }}
          options={
            teams?.map(v => {
              return { value: v.id, name: v.name }
            }) ?? []
          }
        />
        <Select
          disabled={loading || userData.companyId === -1}
          searchable={false}
          placeholder={"Statut"}
          className={"!z-10"}
          defaultValue={userData.status}
          onChange={e => {
            const tmp = Object.assign({}, userData)
            tmp.status = parseInt(e.target.value)
            setUserData(tmp)
          }}
          options={
            [
              {
                value: 1,
                name: "Activé",
              },
              {
                value: -1,
                name: "Désactivé",
              },
            ] as OptionInterface[]
          }
        />
        <div className={"flex"}>
          <ValidateButton
            disabled={!valid || loading}
            validate={"Valider"}
            onClick={() => {
              if (type === "add") {
                setLoading(true)
                const data: NewUser = {
                  name: userData.last_name,
                  first_name: userData.first_name,
                  email: userData.email,
                  scope: userData.scope,
                  status: userData.status,
                  team: userData.teamId,
                  company: userData.companyId,
                }
                apiRequest("/user", { method: "POST", body: data })
                  .then(() => {
                    setLoading(false)
                    setModalDisplay(false)
                    toast.addToast(successToast("Collaborateur ajouté"))
                  })
                  .catch(err => {
                    setLoading(false)
                    if (err instanceof APIError && err.code === 409) {
                      toast.addToast(warningToast("Un utilisateur avec ce mail existe déjà"))
                    } else {
                      if (err instanceof APIError) {
                        toast.addToast(errorToast(err.message))
                      }
                    }
                  })
              } else {
                setLoading(true)
                const data = {
                  email: userData.email,
                  name: userData.last_name,
                  first_name: userData.first_name,
                  team: userData.teamId,
                  scope: userData.scope,
                  status: userData.status,
                  avatar: userData.avatar,
                  company: userData.companyId,
                }
                apiRequest("/user/" + userData.id, {
                  method: "PUT",
                  body: data,
                })
                  .then(() => {
                    setLoading(false)
                    setModalDisplay(false)
                    toast.addToast(successToast("Collaborateur mis à jour"))
                  })
                  .catch(err => {
                    setLoading(false)
                    if (err instanceof APIError && err.code === 409) {
                      toast.addToast(warningToast("Un utilisateur avec ce mail existe déjà"))
                    } else {
                      toast.addToast(errorToast("Erreur lors de la mis à jour du collaborateur"))
                    }
                  })
              }
            }}
            spinning={loading}
          />
          <CancelButton
            cancel={"Annuler"}
            onClick={() => {
              setModalDisplay(false)
              setUserData(emptyUserData)
            }}
          />
        </div>
      </form>
    </Modal>
  )
}
