import { useApi } from "@kamae-apps/shared/Hooks/useApi"
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage"
import { Nullable } from "@kamae-apps/shared/Types/Nullable"
import { TScenario } from "@kamae-apps/shared/Types/TScenario"
import ValidateButton from "@kamae-apps/shared/old/Component/Button/ValidateButton"
import Input from "@kamae-apps/shared/old/Component/Input/Input"
import Select from "@kamae-apps/shared/old/Component/Input/Select"
import TextArea from "@kamae-apps/shared/old/Component/Input/TextArea"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest } from "@kamae-apps/shared/utils"
import React, { Dispatch, useEffect } from "react"
import { Action } from "./Scenario"

interface EditAddScenarioProps {
  scenarioId: Nullable<number>
  languageId: number
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  type: "add" | "update"
  state: TScenario
  reload: () => void
  dispatcher: Dispatch<Action>
}

export default function EditAddScenario(props: EditAddScenarioProps) {
  const { dispatcher } = props

  const toast = useToast()
  const { data: scenario } = useApi<TScenario>("/phishing/scenarios/" + props.scenarioId, {
    disable: !props.scenarioId,
    deps: [props.languageId, props.scenarioId],
    queryParams: {
      languageId: props.languageId,
    },
  })

  useEffect(() => {
    if (scenario) {
      dispatcher({ type: "set", value: scenario })
    }
  }, [scenario, dispatcher])

  const title = props.type === "add" ? "Ajouter un scénario" : "Éditer un scénario"

  return (
    <Modal
      title={title}
      display={props.display}
      setDisplay={props.setDisplay}
      width={"w-1/2"}
    >
      <form className={"flex flex-col items-center gap-4 py-4"}>
        <Input
          placeholder={"Nom"}
          value={props.state.name}
          onChange={e =>
            props.dispatcher({
              type: "edit",
              key: "name",
              value: e.target.value,
            })
          }
        />
        <Select
          options={[
            { name: "Français", value: DefaultLanguage.FR },
            { name: "Anglais", value: DefaultLanguage.EN },
            { name: "Espagnol", value: DefaultLanguage.ES },
            { name: "Allemand", value: DefaultLanguage.DE },
          ]}
          className="w-full"
          defaultValue={props.state.language_id}
          placeholder={"Langue des pièges"}
          onChange={e =>
            props.dispatcher({
              type: "edit",
              key: "language_id",
              value: parseInt(e.target.value),
            })
          }
        />
        <Input
          placeholder={"Logo (facultatif)"}
          value={props.state.logo_file}
          onChange={e =>
            props.dispatcher({
              type: "edit",
              key: "logo_file",
              value: e.target.value.trim(),
            })
          }
        />
        <div className={"flex w-full flex-col items-center gap-6"}>
          <p>Email</p>
          <Input
            placeholder={"Origine"}
            value={props.state.emailFrom}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "emailFrom",
                value: e.target.value.trim(),
              })
            }
          />
          <Input
            placeholder={"Sujet"}
            value={props.state.defaultEmailSubject}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "defaultEmailSubject",
                value: e.target.value,
              })
            }
          />
          <TextArea
            placeholder={"HTML"}
            value={props.state.emailTemplateHtml}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "emailTemplateHtml",
                value: e.target.value,
              })
            }
          />
        </div>
        <div className={"flex w-full flex-col items-center gap-6"}>
          <p>Landing page</p>
          <Input
            placeholder={"URL"}
            value={props.state.landingPageUrl}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "landingPageUrl",
                value: e.target.value.trim(),
              })
            }
          />
          <Input
            placeholder={"URL de redirection"}
            value={props.state.defaultRedirectUrl}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "defaultRedirectUrl",
                value: e.target.value.trim(),
              })
            }
          />
          <TextArea
            placeholder={"HTML"}
            value={props.state.landingPageHtml}
            onChange={e =>
              props.dispatcher({
                type: "edit",
                key: "landingPageHtml",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <ValidateButton
            validate={"Valider"}
            onClick={e => {
              e.preventDefault()
              if (props.type === "add") {
                apiRequest("/phishing/scenarios", {
                  method: "POST",
                  body: props.state,
                }).then(() => {
                  toast.addToast(successToast("Scenario ajouter avec succès"))
                  props.setDisplay(false)
                })
              } else {
                apiRequest(`/phishing/scenarios/${props.scenarioId}?languageId=${props.languageId}`, {
                  method: "PUT",
                  body: props.state,
                }).then(() => {
                  toast.addToast(successToast("Modification enregistrées"))
                  props.reload()
                  props.setDisplay(false)
                })
              }
            }}
          />
        </div>
      </form>
    </Modal>
  )
}
