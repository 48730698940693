import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { errorToast, successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest } from "@kamae-apps/shared/utils"
import { useEffect, useState } from "react"
import { isValidDomain } from "../../../../../../../utils"
import { CompanyData } from "../../../../../Companies"

export const useMailDomains = (company: CompanyData) => {
  const toast = useToast()
  const mailDomains = useApiRequest<string[]>(`/company/${company.id}/sso/mail`)
  const [domains, setDomains] = useState<string[]>([])
  const [newDomain, setNewDomain] = useState("")

  const writeNewDomain = () => {
    if (isValidDomain(newDomain)) {
      setDomains(domains.concat(newDomain))
      setNewDomain("")
    }
  }

  const addDomain = () => {
    setDomains(domains.concat(newDomain))
    setNewDomain("")
  }

  const saveDomains = () => {
    apiRequest<{
      allowed_email_domains: string[]
    }>(`/company/${company.id}/sso/mail`, {
      method: "PUT",
      body: domains,
    })
      .then(resp => {
        if (resp) {
          setDomains(resp.allowed_email_domains)
        }
        toast.addToast(successToast("Domaines mail mis à jour avec succès"))
      })
      .catch(() => {
        toast.addToast(errorToast("Impossible de mettre les domaines mail à jour"))
      })
  }

  const deleteDomain = (domain: string) => {
    setDomains(domains.filter(v => v !== domain))
  }

  useEffect(() => {
    if (mailDomains !== undefined) {
      setDomains(mailDomains)
    }
  }, [mailDomains])

  return {
    mailDomains,
    domains,
    setDomains,
    newDomain,
    setNewDomain,
    writeNewDomain,
    addDomain,
    saveDomains,
    deleteDomain,
  }
}
