import { TFunction } from "i18next"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Nullable } from "../../Types/Nullable"

type CustomTranslateFunction = TFunction | ((key: string, defaultValue?: string) => Nullable<string>)

type ComponentPropsWithoutTranslationProps<P> = Omit<P, keyof WithTranslationProps>

export interface WithTranslationProps {
  customTranslateFunction: CustomTranslateFunction
}

export function withComponentsNamespaceTranslation<P>(Component: FC<P>): FC<ComponentPropsWithoutTranslationProps<P>> {
  return withTranslation(Component, "components")
}

function withTranslation<Props>(
  Component: FC<Props>,
  namespace: string
): FC<ComponentPropsWithoutTranslationProps<Props>> {
  let customTranslateFunction: CustomTranslateFunction = fallbackTranslateFunctionReturnNull

  return (props: ComponentPropsWithoutTranslationProps<Props>) => {
    const { t, ready: canUsei18n } = useTranslation(namespace, {
      useSuspense: false,
    })

    if (canUsei18n) customTranslateFunction = t

    return (
      <Component
        {...(props as Props)}
        customTranslateFunction={customTranslateFunction}
      />
    )
  }
}

const fallbackTranslateFunctionReturnNull = (key: string, defaultValue?: string) => defaultValue

export default withTranslation
