import CancelButton from "@kamae-apps/shared/old/Component/Button/CancelButton"
import DangerButton from "@kamae-apps/shared/old/Component/Button/DangerButton"
import Spinner from "@kamae-apps/shared/old/Component/Button/Spinner"
import Input from "@kamae-apps/shared/old/Component/Input/Input"
import { Modal } from "@kamae-apps/shared/old/Component/NewModal/Modal"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { errorToast, successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest } from "@kamae-apps/shared/utils"
import { ChangeEvent, FC, useCallback, useState } from "react"
import { CompanyData } from "../../Companies"

type DemoDataGenerationProps = {
  company: CompanyData
  display: boolean
  setDisplay: (v: boolean) => void
}

export const DemoDataGenerationModal: FC<DemoDataGenerationProps> = ({ company, display, setDisplay }) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [title, header, toastText, generateButtonText, toastError] = [
    "Générer des données de test ⚠️",
    "Va créer des équipes et des utilisateurs. A n'utiliser que sur des entreprises vides",
    "Données générées",
    "Générer les données",
    "Erreur lors de la génération des données",
  ]
  const [nbUsersToCreate, setNbUsersToCreate] = useState(50)
  const [adminLastName, setAdminLastName] = useState("admin")
  const [adminFirstName, setAdminFirstName] = useState("admin")
  const [adminMail, setAdminMail] = useState("admin.admin@kamae.fr")
  const changeNumberOfUsersToCreate = (e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value === "" ? setNbUsersToCreate(0) : setNbUsersToCreate(parseInt(e.currentTarget.value))
  }
  const generateDemoData = useCallback(() => {
    apiRequest(`/admin/companies/${company.id}/demo-data`, {
      method: "POST",
      body: {
        nbUsers: nbUsersToCreate,
        adminLastName: adminLastName,
        adminFirstName: adminFirstName,
        adminMail: adminMail,
      },
    })
      .then(() => {
        toast.addToast(successToast(toastText))
        setLoading(false)
        setDisplay(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
        toast.addToast(errorToast(toastError))
      })
  }, [company.id, nbUsersToCreate, adminLastName, adminFirstName, adminMail])

  return (
    <Modal
      title={title}
      display={display}
      setDisplay={setDisplay}
      width={"w-3/5"}
    >
      <div className={"flex w-full flex-col items-center px-4"}>
        <p className={"text-center text-xl"}>
          <span className={"text-red-500"}>{header}</span>
        </p>
        <div className={"mt-10 mb-4"}>
          <Input
            className={"my-4 !ml-0 !w-auto"}
            placeholder={"Nombre d'utilisateurs"}
            value={nbUsersToCreate}
            onChange={changeNumberOfUsersToCreate}
          />
          <Input
            className={"my-4 !ml-0 !w-auto"}
            placeholder={"Nom admin"}
            value={adminLastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAdminLastName(e.currentTarget.value)
            }}
          />
          <Input
            className={"my-4 !ml-0 !w-auto"}
            placeholder={"Prénom admin"}
            value={adminFirstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAdminFirstName(e.currentTarget.value)
            }}
          />
          <Input
            className={"my-4 !ml-0 !w-auto"}
            placeholder={"Mail admin"}
            value={adminMail}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAdminMail(e.currentTarget.value)
            }}
          />
          <p>
            Va créer 9 équipes, {nbUsersToCreate} utilisateurs répartis équitablement dans ces équipes, et des réponses
            à plusieurs elearning et à un challenge. Création d'un admin {adminFirstName} {adminLastName} dans l'équipe
            Admin
          </p>
          <DangerButton
            className="!inline"
            disabled={loading}
            onClick={() => {
              setLoading(true)
              generateDemoData()
            }}
            spinning={loading}
          >
            {generateButtonText}
          </DangerButton>
          <CancelButton
            className="!inline"
            onClick={() => setDisplay(false)}
            cancel="Annuler"
          />
        </div>
      </div>
    </Modal>
  )
}
