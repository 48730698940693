export class APIError extends Error {
  code = 0
  message = ""
  data?: string = undefined

  constructor(code: number, message: string, data: string | undefined) {
    super()
    this.code = code
    this.message = message
    this.data = data
  }
}

// biome-ignore lint/complexity/noBannedTypes: Empty object that is extended by other interfaces, to rework in the future
export type APIRequest = {}

export interface APIResponse<T> {
  code: number
  message: string
  data?: T
}
