import { FC, ReactElement } from "react"

interface ConditionalRenderProps {
  condition: boolean
  children: ReactElement
}

export const ConditionalRender: FC<ConditionalRenderProps> = ({ condition, children }) => {
  return condition ? children : null
}
