import clsx from "clsx"
import React, { forwardRef, useEffect } from "react"
import { Day, useLilius } from "use-lilius"
import { dateToUTC } from "../../../utils"
import Card from "../Card/Card"

interface CalendarProps {
  days: string[]
  month: string[]
  value: Date
  setValue: (v: Date) => void
  todaySelectable?: boolean
  dayBeforeSelectable?: boolean
}

function dateEquals(d1: Date, d2: Date): boolean {
  return (
    d1 !== undefined &&
    d2 !== undefined &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  )
}

function dateBefore(d1: Date, d2: Date): boolean {
  return (
    d1 !== undefined &&
    d2 !== undefined &&
    (d1.getFullYear() < d2.getFullYear() ||
      (d1.getFullYear() === d2.getFullYear() &&
        (d1.getMonth() < d2.getMonth() || (d1.getMonth() === d2.getMonth() && d1.getDate() < d2.getDate()))))
  )
}

const Calendar = forwardRef<HTMLDivElement, CalendarProps>((props: CalendarProps, ref) => {
  const cal = useLilius({
    weekStartsOn: Day.MONDAY,
    selected: [props.value],
    viewing: props.value,
  })
  const today = new Date()
  const { setSelected, setViewing } = cal
  useEffect(() => {
    setSelected([props.value])
    setViewing(props.value)
  }, [props.value, setSelected, setViewing])
  return (
    <Card
      className={"border-1 w-[25.5rem] rounded !p-8"}
      ref={ref}
    >
      <div className={"flex items-center justify-between pb-2"}>
        <div className={"flex"}>
          <p className={"pr-1"}>{props.month[cal.viewing.getMonth()]}</p>
          <p>{cal.viewing.getFullYear()}</p>
        </div>
        <div className={"font-gilroy-medium flex text-2xl"}>
          <button
            className={"select-none rounded-full px-4 transition-colors hover:bg-gray-200"}
            onClick={() => {
              cal.viewPreviousMonth()
            }}
          >
            {"<"}
          </button>
          <button
            className={"select-none rounded-full px-4 transition-colors hover:bg-gray-200"}
            onClick={() => {
              cal.viewNextMonth()
            }}
          >
            {">"}
          </button>
        </div>
      </div>
      <div className={"grid select-none grid-cols-7 justify-items-center gap-2"}>
        {props.days.map((d, i) => {
          return <p key={i}>{d}</p>
        })}
        {cal.calendar.map((v, i) => {
          return v.map((v2, i2) => {
            return v2.map((v3, i3) => {
              const css = clsx(
                "flex h-8 w-8 items-center justify-center p-5",
                dateEquals(v3, cal.selected[0])
                  ? "border-1 border-primary-400 bg-primary-100 text-primary cursor-pointer rounded-full"
                  : dateEquals(v3, today)
                    ? clsx(props.todaySelectable !== false ? "cursor-pointer text-blue-400" : "text-gray-300")
                    : dateBefore(v3, today) && !props.dayBeforeSelectable
                      ? "text-gray-300"
                      : v3.getMonth() === cal.viewing.getMonth()
                        ? "cursor-pointer text-black"
                        : "cursor-pointer text-gray-400"
              )
              return (
                <p
                  key={i3 + i2 * 7 + i * 7 * 5}
                  className={css}
                  onClick={() => {
                    if (!dateBefore(v3, today) || props.dayBeforeSelectable) {
                      cal.setSelected([v3])
                      props.setValue(dateToUTC(v3))
                    }
                  }}
                >
                  {v3.getDate()}
                </p>
              )
            })
          })
        })}
      </div>
    </Card>
  )
})

export default Calendar
