import clsx from "clsx"
import React, { FC } from "react"

type MenuTabsProps = {
  title: string
  active: boolean
  setNavigate: () => void
}

export const MenuTab: FC<MenuTabsProps> = ({ title, active, setNavigate }) => {
  return (
    <div
      className={clsx(
        "cursor-pointer rounded-tl-2xl rounded-tr-2xl px-6 py-2 drop-shadow",
        active ? "bg-white" : "bg-gray-100 hover:bg-gray-50"
      )}
      onClick={() => setNavigate()}
    >
      <p className={"text-lg"}>{title}</p>
    </div>
  )
}
