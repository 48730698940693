export enum BeltColor {
  white = "white",
  yellow = "yellow",
  orange = "orange",
  green = "green",
  blue = "blue",
  purple = "purple",
  brown = "brown",
  black = "black",
  red = "red",
  bronze = "bronze",
  silver = "silver",
  gold = "gold",
  god = "god",
}

export enum BeltProgressionAuthorized {
  full = 11, // Exclude God belt
  restricted = 7, // No belts beyond black
  discovery = 2, // No belts beyond orange
}

export const belt = [
  BeltColor.white,
  BeltColor.yellow,
  BeltColor.orange,
  BeltColor.green,
  BeltColor.blue,
  BeltColor.purple,
  BeltColor.brown,
  BeltColor.black,
  BeltColor.red,
  BeltColor.bronze,
  BeltColor.silver,
  BeltColor.gold,
  BeltColor.god,
]

export function getColorFromBelt(belt: BeltColor) {
  switch (belt) {
    case BeltColor.white:
      return "text-gray-300"
    case BeltColor.yellow:
      return "text-amber-200"
    case BeltColor.orange:
      return "text-orange-500"
    case BeltColor.green:
      return "text-green-600"
    case BeltColor.blue:
      return "text-blue-500"
    case BeltColor.purple:
      return "text-purple-700"
    case BeltColor.brown:
      return "text-amber-800"
    case BeltColor.black:
      return "text-black"
    case BeltColor.red:
      return "text-red-600"
    case BeltColor.bronze:
      return "text-amber-600"
    case BeltColor.silver:
      return "text-zinc-200"
    case BeltColor.gold:
      return "text-yellow-300"
    case BeltColor.god:
      return "text-blue-300"
  }
}
