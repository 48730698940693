export function getCategoryName(category: string): string {
  switch (category) {
    case "RGPD":
      return "RGPD"
    case "HomePage":
      return "À la maison"
    case "Company":
      return "Cybersécurité"
    case "Other":
      return "Autre"
    default:
      return ""
  }
}
