export function getBorderColor(className?: string, def = "border-black") {
  const reg = new RegExp("border-[a-z0-9-]+")
  const res = reg.exec(className ?? "")
  if (res !== null) {
    def = res[0]
  }
  return def
}

export function validMail(mail: string): boolean {
  return new RegExp("^[\\w\\d.-]+@[\\w\\d.-]+\\.[\\w\\d_-]{2,}$").test(mail)
}

/**
 * Change status (int) in the correspondant string
 * @param status The int status
 */
export function statusToString(status: number): string {
  switch (status) {
    case -1:
      return "Désactivé"
    case 1:
      return "Activé"
    default:
      return ""
  }
}

export function clone<T>(obj: T): T {
  return Object.assign({}, obj)
}

export function isValidDomain(value: string): boolean {
  const regex = /^([a-z0-9_-]+\.)*([a-z0-9_-]+\.)[a-z0-9_-]{2,}$/
  return regex.test(value)
}
