import clsx from "clsx"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Calendar as CalendarIcon } from "react-feather"
import { formatDate, getDateFromUser, isValidDate } from "../../../utils"
import PrimaryButton from "../Button/PrimaryButton"
import Calendar from "../Calendar/Calendar"
import { ConditionalRender } from "../ConditionalRender/ConditionalRender"
import Input from "./Input"

interface CalendarInputProps {
  place?: "TOP" | "LEFT" | "RIGHT" | "BOTTOM" | "RIGHT_BOTTOM" | "RIGHT_TOP"
  value: Date
  setValue: (v: Date) => void
  reverseMonth?: boolean
  disabled?: boolean
  placeHolder?: string
  className?: string
  setIsDateValid?: (value: boolean) => void
  displayCalendarButton?: boolean
  openCalendar?: boolean
}

export default function CalendarInput(props: CalendarInputProps) {
  const [display, setDisplay] = useState(typeof props.openCalendar === "boolean" ? props.openCalendar : false)
  const calendar = useRef<HTMLDivElement>(null)
  const button = useRef<HTMLButtonElement>(null)
  const [dateView, setDateView] = useState("")
  const [isValidInput, setIsValidInput] = useState(true)
  let placement: string = "top-full left-1/2 -translate-x-1/2"
  switch (props.place) {
    case "TOP":
      placement = "bottom-full left-1/2 -translate-x-1/2"
      break
    case "LEFT":
      placement = "top-1/2 -translate-y-1/2 right-full"
      break
    case "RIGHT":
      placement = "top-1/2 -translate-y-1/2 left-full"
      break
    case "BOTTOM":
      placement = "top-full left-1/2 -translate-x-1/2"
      break
    case "RIGHT_BOTTOM":
      placement = "top-0 left-full"
      break
    case "RIGHT_TOP":
      placement = "bottom-0 left-full"
      break
  }

  const { value, setValue, reverseMonth, displayCalendarButton } = props

  const clickHandle = useCallback(
    (e: MouseEvent) => {
      if (e.target === button.current || (e.target as HTMLElement).parentElement === button.current) {
        e.stopPropagation()
        return
      }
      if (calendar.current && !calendar.current.contains(e.target as Node)) {
        setDisplay(false)
        e.stopPropagation()
        e.preventDefault()
      }
    },
    [calendar]
  )

  useEffect(() => {
    setDateView(formatDate(value, reverseMonth))
  }, [value, reverseMonth])

  useEffect(() => {
    document.addEventListener("mousedown", clickHandle)
    setDateView(formatDate(value, reverseMonth))
    return () => {
      document.removeEventListener("mousedown", clickHandle)
    }
  }, [clickHandle, reverseMonth, value])

  useEffect(() => {
    if (props.setIsDateValid) {
      props.setIsDateValid(isValidInput)
    }
  }, [props, isValidInput])

  return (
    <div className={clsx("relative mx-4", props.className)}>
      <div className={"flex"}>
        <Input
          className={clsx(
            "mx-0 grow",
            displayCalendarButton !== false && "rounded-r-none",
            isValidInput ? "" : "border-2 border-red-500"
          )}
          value={dateView}
          placeholderColor={isValidInput ? "" : "text-red-500"}
          onChange={e => {
            setDateView(e.target.value)
            if (isValidDate(e.target.value)) {
              setValue(getDateFromUser(e.target.value, reverseMonth))
              setIsValidInput(true)
            } else {
              setIsValidInput(false)
            }
          }}
          disabled={props.disabled}
          placeholder={props.placeHolder}
        />
        <ConditionalRender condition={displayCalendarButton !== false}>
          <PrimaryButton
            ref={button}
            tabIndex={-1}
            className={"!m-0 rounded-l-none !p-2"}
            onClick={e => {
              setDisplay(prev => !prev)
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <CalendarIcon className={"no-fill"} />
          </PrimaryButton>
        </ConditionalRender>
      </div>
      <ConditionalRender condition={display}>
        <div className={clsx("absolute z-10", placement)}>
          <Calendar
            days={["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"]}
            month={[
              "Janvier",
              "Février",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Juillet",
              "Août",
              "Septembre",
              "Octobre",
              "Novembre",
              "Décembre",
            ]}
            value={props.value}
            setValue={value => {
              props.setValue(value)
              setIsValidInput(true)
            }}
            ref={calendar}
            dayBeforeSelectable={true}
          />
        </div>
      </ConditionalRender>
    </div>
  )
}
