import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { APIError, APIRequest } from "../Types/API"
import { apiRequest } from "../utils"

interface UseApiRequestOption {
  method?: string
  body?: APIRequest
  reset?: boolean
  headers?: Record<string, string>
  name?: string
  if?: boolean
  onError?: (e: APIError) => void
}

export function useApiRequest<T>(
  url: string,
  deps: React.DependencyList = [],
  options?: UseApiRequestOption
): T | undefined {
  const [data, setData] = useState<T>()
  const { i18n } = useTranslation()
  const stringDeps = JSON.stringify(deps)
  const onError = options?.onError

  const optionsString = JSON.stringify(options)
  const opt: UseApiRequestOption | undefined = useMemo(
    () => (optionsString !== undefined ? JSON.parse(optionsString) : undefined),
    [optionsString]
  )
  // biome-ignore lint/correctness/useExhaustiveDependencies: Too touchy, the useApiRequest might trigger infinitely if we add all the dependencies : waiting for rework
  useEffect(() => {
    if (opt?.if === false) {
      return
    }
    if (opt?.reset ?? false) {
      setData(undefined)
    }
    apiRequest<T>(url, {
      method: opt?.method,
      body: opt?.body,
      name: opt?.name,
      headers: {
        "X-Targeted-Language": i18n.language,
        ...opt?.headers,
      },
    })
      .then(response => {
        if (response != null) {
          setData(response)
        }
      })
      .catch(err => {
        if (err instanceof APIError) {
          if (onError !== undefined) {
            onError(err)
          } else {
            console.warn(`Error in request. Code : ${err.code}. Message : ${err.message}`)
          }
        } else {
          console.error(err)
        }
      })
  }, [url, opt, stringDeps, i18n.language, onError])
  return data
}
