import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest"
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Module } from "@kamae-apps/shared/Types/Module"
import { useToast } from "@kamae-apps/shared/old/Component/Toast/Context"
import { errorToast, successToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest } from "@kamae-apps/shared/utils"
import { useCallback, useEffect, useState } from "react"
import { AddELearningsToCompany, CompanyElearningAdmin, ElearningAdmin } from "../../../../../Types/ELearning"
import { CompanyData } from "../../../Companies"

export const useElearningContainer = (company: CompanyData) => {
  const toast = useToast()
  const [width] = useWindowSize()
  const companyModules = useApiRequest<Module[]>(`/module/${company.id}`)
  const elearnings = useApiRequest<ElearningAdmin[]>(`/elearning/all/company/${company.id}`)
  const companyElearning = useApiRequest<CompanyElearningAdmin[]>(`/elearning/company/${company.id}`)
  const [check, setCheck] = useState<boolean[]>([])
  const [date, setDate] = useState<Date[]>([])
  const [loading, setLoading] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const [displayCalendar, setDisplayCalendar] = useState(false)
  const [elearningUpdateIndex, setElearningUpdateIndex] = useState<number>(-1)

  const changeCheckAll = useCallback(() => {
    setAllChecked(prev => {
      setCheck(prevChecked => prevChecked.map(() => !prev))
      return !prev
    })
  }, [])

  const saveElearnings = useCallback(() => {
    setLoading(true)
    const companyElearningId = companyElearning?.map(v => v.id) ?? []
    const addData: AddELearningsToCompany[] = check
      .map((v, i) => {
        return {
          elearningId: v ? elearnings!![i].id : -1,
          release_date: date[i].toISOString().split("T")[0],
        }
      })
      .filter(v => v.elearningId !== -1 && !companyElearningId.includes(v.elearningId))
    apiRequest("/elearning/company/" + company.id, {
      method: "POST",
      body: addData,
    })
      .then(() => {
        elearnings
          ?.filter((v, i) => !check[i] && companyElearningId.includes(v.id))
          .forEach(v => {
            apiRequest("/elearning/" + v.id + "/company/" + company.id, {
              method: "DELETE",
            })
          })
        elearnings?.forEach((v, i) => {
          if (check[i] && companyElearningId.includes(v.id)) {
            const month = date[i].getUTCMonth() + 1
            const day = date[i].getUTCDate()
            const stringDate =
              date[i].getUTCFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day)
            apiRequest("/elearning/" + v.id + "/company/" + company.id, {
              method: "PUT",
              body: { release_date: stringDate },
            })
          }
        })
        toast.addToast(successToast("Elearning mis à jour"))
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        toast.addToast(errorToast())
        setLoading(false)
      })
  }, [check, company.id, companyElearning, date, elearnings, toast])

  useEffect(() => {
    const companyElearningId = companyElearning?.map(v => v.id) ?? []
    if (elearnings !== undefined) {
      setCheck(elearnings.map(v => companyElearningId.includes(v.id)))
      setDate(
        elearnings.map(v => {
          const companyElearningAdmin = companyElearning?.find(e => v.id === e.id)
          if (companyElearningAdmin === undefined) {
            const licenseStart = new Date(company.license_start_date)
            const d = new Date()
            d.setMonth(licenseStart.getMonth(), 1)
            if (company.status !== CompanyStatus.Trial) {
              d.setMonth(d.getMonth() + v.monthDelta, 1)
            }
            return d
          } else {
            return companyElearningAdmin.release_date
          }
        })
      )
    }
  }, [companyElearning, elearnings, company.license_start_date, company.status])

  useEffect(() => {
    if (check.filter(v => !v).length === 0) {
      setAllChecked(true)
    } else {
      setAllChecked(false)
    }
  }, [check])

  return {
    loading,
    allChecked,
    elearnings,
    check,
    setCheck,
    date,
    setDate,
    width,
    companyModules,
    changeCheckAll,
    saveElearnings,
    displayCalendar,
    setDisplayCalendar,
    elearningUpdateIndex,
    setElearningUpdateIndex,
  }
}
