import { ToastColor, ToastOptions } from "./Toast"

export function errorToast(message?: string): ToastOptions {
  return {
    children: `Erreur: ${message}`,
    timeout: 1500,
    color: ToastColor.danger,
  }
}

export function successToast(message?: string): ToastOptions {
  return {
    children: message,
    timeout: 1500,
    color: ToastColor.success,
  }
}

export function warningToast(message?: string): ToastOptions {
  return {
    children: message,
    timeout: 1500,
    color: ToastColor.warning,
  }
}
