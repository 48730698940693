import { BackendModule } from "i18next"

const LazyImportPlugin: BackendModule = {
  type: "backend",
  init: function (services, backendOptions, i18nextOptions) {},
  read: function (language, namespace, callback) {
    import(/* webpackChunkName: "i18n/[request]" */ `./${language}/${namespace}.json`).then(obj => {
      callback(null, obj)
    })
  },
}

export default LazyImportPlugin
