import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { File, FileMinus } from "react-feather"
import { v4 } from "uuid"

interface FileInputProps {
  placeHolder?: string
  className?: string
  value?: File | null
  setValue?: React.Dispatch<React.SetStateAction<File | null>>
  accept?: string
  disabled?: boolean
}

export default function FileInput(props: FileInputProps) {
  const id = v4()
  const css = clsx(
    "kamae-input bg-primary-50 relative mx-4 flex w-3/5 justify-between rounded px-4 py-3 drop-shadow focus-within:outline focus-within:outline-2 focus-within:outline-black focus:outline-none",
    props.className,
    props.disabled || "cursor-pointer"
  )
  const [text, setText] = useState("")
  const labelCss = clsx(
    "bg-primary-50 absolute cursor-pointer rounded p-0.5 transition-all",
    text !== "" && "-translate-y-6"
  )
  const fileCss = clsx("no-fill", props.disabled ? "text-gray-300" : "text-black")
  const [v, setV] = useState("")
  useEffect(() => {
    if (props.value === null) {
      setText("")
    }
  }, [props.value])
  return (
    <label
      className={css}
      htmlFor={id}
    >
      <div className={labelCss}>
        <span className={"font-gilroy-medium text-sm text-gray-400"}>
          {props.placeHolder ?? "Aucun fichier selectionné"}
        </span>
      </div>
      <div className={"cursor-pointer"}>
        <span className={"text-black"}>{text}</span>
      </div>
      <input
        value={v}
        className={"h-0 w-0"}
        disabled={props.disabled}
        type={"file"}
        accept={props.accept}
        id={id}
        onChange={e => {
          setV(e.target.value)
          if (e.target.files !== null) {
            setText(e.target.files[0].name)
            if (props.setValue !== undefined) {
              props.setValue(e.target.files[0])
            }
          }
        }}
      />
      {props.value !== null ? (
        <FileMinus
          className={fileCss}
          onClick={e => {
            if (props.setValue !== undefined) {
              props.setValue(null)
              setV("")
            }
            e.preventDefault()
            setText("")
          }}
        />
      ) : (
        <File className={fileCss} />
      )}
    </label>
  )
}
