export enum Scope {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  USER = "USER",
}

export function scopeHas(scope: Scope | undefined, other: Scope): boolean {
  switch (scope) {
    case Scope.SUPER_ADMIN:
      return true
    case Scope.COMPANY_ADMIN:
      return other !== Scope.SUPER_ADMIN
    case Scope.USER:
      return other === Scope.USER
    case undefined:
      return false
  }
}
