import clsx from "clsx"
import React, { useCallback, useEffect, useState } from "react"
import { X } from "react-feather"
import "./Toast.css"

export interface ToastInterface {
  addToast: (toast: ToastOptions) => void
}

export enum ToastColor {
  success = "border-emerald-800",
  danger = "border-red-600",
  warning = "border-amber-500",
}

export interface ToastOptions {
  color: ToastColor
  children: any
  timeout: number
}

interface ToastProps extends ToastOptions {
  delete: (id: number) => void
  id: number
}

interface ToastContainerState {
  toasts: Map<number, JSX.Element>
}

//Container of Toast to add to the page to use Toast
export class ToastContainer extends React.Component<{}, ToastContainerState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      toasts: new Map<number, JSX.Element>(),
    }
    this.deleteToast = this.deleteToast.bind(this)
    this.addToast = this.addToast.bind(this)
  }

  deleteToast(id: number) {
    this.state.toasts.delete(id)
    this.setState({ toasts: this.state.toasts })
  }

  async addToast(toast: ToastOptions) {
    let max = -1
    this.state.toasts.forEach((value, key) => {
      if (key > max) {
        max = key
      }
    })
    this.state.toasts.set(max + 1, this.makeToast(toast, max + 1))
    this.setState({ toasts: this.state.toasts })
  }

  makeToast(value: ToastOptions, index: number) {
    return (
      <Toast
        color={value.color}
        delete={this.deleteToast}
        timeout={value.timeout ?? 3000}
        id={index}
        key={index}
      >
        {value.children}
      </Toast>
    )
  }

  render() {
    let t: JSX.Element[] = []
    this.state.toasts.forEach(value => {
      t.push(value)
    })
    return (
      <div
        className={
          "md:min-w-56 fixed right-0 top-0 z-50 flex max-h-full w-4/5 flex-col overflow-hidden py-2 pr-4 md:max-w-sm"
        }
      >
        {t}
      </div>
    )
  }
}

export function Toast(props: ToastProps) {
  const [animation, setAnimation] = useState("toast-in")
  const [timeout, setTimeout] = useState(0)
  const close = useCallback(() => {
    setAnimation("toast-out")
    window.setTimeout(() => {
      props.delete(props.id)
    }, 300)
  }, [props])
  useEffect(() => {
    setTimeout(
      window.setTimeout(() => {
        close()
      }, 300 + props.timeout)
    )
  }, [props.timeout, close])
  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [timeout])
  const css = clsx(
    "relative m-1 mb-2 flex w-full justify-between overflow-hidden rounded border-l-8 bg-gray-300 p-2 shadow-md",
    props.color
  )
  return (
    <div
      className={css}
      style={{
        animationDuration: "0.3s",
        animationName: animation,
        animationFillMode: "forwards",
      }}
    >
      {props.children}
      <X
        className={"cursor-pointer"}
        onClick={close}
      />
    </div>
  )
}
