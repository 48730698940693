import React from "react"
import "./index.css"
import { CryptrProvider } from "@cryptr/cryptr-react"
import { ApiStatusStateProvider } from "@kamae-apps/shared/Hooks/useApiStatus/Context"
import { createRoot } from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { Provider as Store } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./Components/App/App"
import i18n from "./i18n/i18n"
import { store } from "./store/store"
import { cryptrConf } from "./variable"

const root = createRoot(document.getElementById("root")!!)

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <CryptrProvider {...cryptrConf}>
          <Store store={store}>
            <ApiStatusStateProvider>
              <App />
            </ApiStatusStateProvider>
          </Store>
        </CryptrProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
)
