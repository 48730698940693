import clsx from "clsx"
import { MouseEventHandler, forwardRef } from "react"
import { getBorderColor } from "../../../utils"
import Spinner from "./Spinner"

export interface ButtonProps {
  children: any
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  spinning?: boolean
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  title?: string
  tabIndex?: number
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const border = getBorderColor(props.className)
  const css = clsx(
    "button mx-6 my-2 flex items-center justify-center rounded border-2 px-8 py-2 transition-colors transition-transform",
    border,
    props.className
  )
  return (
    <button
      tabIndex={props.tabIndex}
      ref={ref}
      type={props.type}
      className={css}
      onClick={props.onClick}
      disabled={props.disabled}
      title={props.title}
    >
      <Spinner hidden={!props.spinning} />
      {props.children}
    </button>
  )
})

export default Button
