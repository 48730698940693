import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest"
import Card from "@kamae-apps/shared/old/Component/Card/Card"
import { CompanyData } from "../../Companies"

interface CompanyStatProps {
  company: CompanyData
}

export default function CompanyStat(props: CompanyStatProps) {
  const love = useApiRequest<number>(`/company/${props.company.id}/stat/elearning/love`)
  const total = useApiRequest<number>(`/company/${props.company.id}/stat/elearning/done`)
  const score = useApiRequest<number>(`/company/${props.company.id}/stat/elearning/score`)
  const monthScore = useApiRequest<number>(`/company/${props.company.id}/stat/month/score`)
  const monthDone = useApiRequest<number>(`/company/${props.company.id}/stat/month/done`)
  const activeUsers = useApiRequest<number>(`/company/${props.company.id}/stat/active-users/count`)
  const totalUsers = useApiRequest<number>(`/company/${props.company.id}/users/count`)
  const formatter = Intl.NumberFormat("fr", {
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 3,
  })
  return (
    <>
      <div className={"flex flex-wrap gap-4 p-2"}>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Nombre d'elearning terminé</p>
          <p className={"text-4xl"}>{total ?? "0"}</p>
        </Card>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Score moyen des utilisateurs</p>
          <p className={"text-4xl"}>{score !== undefined ? formatter.format(score) : "∅"}</p>
        </Card>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Appréciation moyenne des elearning</p>
          <p className={"text-4xl"}>{love !== undefined ? formatter.format(love) : "∅"} / 5</p>
        </Card>
      </div>
      <div className={"flex flex-wrap gap-4 p-2"}>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Nombres de challenges terminés de ce mois</p>
          <p className={"text-4xl"}>{monthDone ?? 0}</p>
        </Card>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Score moyen des challenges de ce mois</p>
          <p className={"text-4xl"}>{monthScore !== undefined ? formatter.format(monthScore) : "∅"}</p>
        </Card>
        <Card className={"flex flex-col items-center drop-shadow-md"}>
          <p className={"text-primary text-xl"}>Nombre d'utilisateurs actifs</p>
          <p className={"text-4xl"}>
            {activeUsers ?? 0} / {totalUsers ?? 0}
          </p>
        </Card>
      </div>
    </>
  )
}
