import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage"
import { LoginMethod } from "@kamae-apps/shared/Types/Company/LoginMethod"
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany"
import Button from "@kamae-apps/shared/old/Component/Button/Button"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import Table, { Column, SortedOrder } from "@kamae-apps/shared/old/Component/Table/Table"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Eye } from "react-feather"
import { useNavigate } from "react-router-dom"
import config from "../../variable"
import CompanyImport from "./CompanyPage/Containers/CompanyImport"
import { InformationContainer } from "./CompanyPage/Containers/InformationContainer/InformationContainer"

export interface CompanyData {
  id: number
  name: string
  status: CompanyStatus
  loginMethod: LoginMethod
  defaultLanguageId: DefaultLanguage
  idp?: string
  license_start_date: Date
  license_end_date: Date
  max_users: number
  max_admins: number
  phishing_enabled: boolean
  sso_enabled: boolean
  lms_enabled: boolean
  onboarding_completed: boolean
  dir_sync_enabled: boolean
  teams_enabled: boolean
  dir_sync_activated: boolean
  external_customer_id?: string
  automated_email_enabled: boolean
}

export const emptyCompanyData: CompanyData = {
  id: 0,
  name: "",
  status: CompanyStatus.Customer,
  loginMethod: LoginMethod.MagicLink,
  defaultLanguageId: DefaultLanguage.FR,
  idp: undefined,
  license_start_date: new Date(),
  license_end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  max_users: 0,
  max_admins: 3,
  phishing_enabled: false,
  sso_enabled: false,
  lms_enabled: false,
  onboarding_completed: false,
  dir_sync_enabled: false,
  teams_enabled: false,
  dir_sync_activated: false,
  automated_email_enabled: true,
}

export default function Companies() {
  const cols: Column[] = useMemo(
    () => [
      { name: "name", title: "Nom" },
      { name: "", title: "", sortable: false },
      { name: "score", title: "Points" },
      { name: "total_user", title: "Nb user" },
      { name: "status", title: "Statut" },
      { name: "view", title: "Visualiser", sortable: false },
    ],
    []
  )

  const navigate = useNavigate()
  const [companyData, setCompanyData] = useState(emptyCompanyData)
  const [newCompanyDisplay, setNewCompanyDisplay] = useState(false)
  const [importCompaniesDisplay, setImportCompaniesDisplay] = useState(false)

  const buttonAdd = (
    <>
      <Button
        onClick={() => {
          setImportCompaniesDisplay(true)
        }}
        className={
          "border-primary text-primary hover:bg-primary grow whitespace-nowrap p-2 transition-colors hover:text-white"
        }
      >
        Importer des entreprises
      </Button>
      <Button
        onClick={() => {
          setNewCompanyDisplay(true)
        }}
        className={
          "border-primary text-primary hover:bg-primary grow whitespace-nowrap p-2 transition-colors hover:text-white"
        }
      >
        Ajouter une entreprise
      </Button>
    </>
  )

  const line = useCallback(
    (c: TCompany) => {
      return [
        c.name,
        <img
          src={config.api + "/logo/" + c.logo}
          alt={"LOGO"}
          className={"ml-4 inline h-10"}
        />,
        c.global_score,
        c.total_user,
        CompanyStatus[c.status],
        <button
          onClick={() => {
            navigate(`/companies/${c.id}`)
          }}
        >
          <Eye className={"no-fill text-gray-400"} />
        </button>,
      ]
    },
    [navigate]
  )

  useEffect(() => {
    if (!newCompanyDisplay) {
      setCompanyData(emptyCompanyData)
    }
  }, [newCompanyDisplay])

  return (
    <>
      <Table
        sortableColumn={cols}
        line={line}
        url={"/company/all"}
        defaultSort={"id"}
        defaultOrder={SortedOrder.DESC}
        actions={buttonAdd}
        searchPlaceholder={"Rechercher"}
        search
        next={"Page suivante"}
        previous={"Page précédente"}
        persistConf
      />
      <Modal
        title={"Ajouter une entreprise"}
        display={newCompanyDisplay}
        setDisplay={setNewCompanyDisplay}
        width={"w-3/4"}
        overflow={true}
      >
        <InformationContainer
          company={companyData}
          setCompany={setCompanyData}
          isNewCompany={true}
        />
      </Modal>
      <Modal
        title={"Importer des entreprises (AXA)"}
        display={importCompaniesDisplay}
        setDisplay={setImportCompaniesDisplay}
        width={"w-3/4"}
        overflow={true}
      >
        <CompanyImport></CompanyImport>
      </Modal>
    </>
  )
}
