import { useApiStatusSharedState } from "./Context"

export default function useApiStatus() {
  const [status, setStatus] = useApiStatusSharedState()
  return {
    code: status.code,
    message: status.message,
    setStatus: setStatus,
  }
}
