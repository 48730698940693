import clsx from "clsx"
import { MouseEventHandler, ReactNode, forwardRef } from "react"
import Button from "./Button"

interface PrimaryButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  spinning?: boolean
  children?: ReactNode
  title?: string
  className?: string
  linkTo?: string
  tabIndex?: number
  invertedColors?: boolean
}

const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>((props, ref) => {
  const { linkTo } = props // destructuring props and separating the linkTo prop
  const css = clsx(
    "focus:outline:none  disabled:border-primary-200 disabled:text-primary-200 whitespace-nowrap  disabled:hover:bg-white",
    props.invertedColors
      ? "border-primary hover:text-primary bg-primary text-white hover:bg-white"
      : "border-primary text-primary hover:bg-primary bg-white hover:text-white",
    props.className
  )

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (linkTo) {
      window.location.href = linkTo // redirect to the link when clicked
    }
    if (props.onClick) {
      props.onClick(event) // call the original onClick function if it exists
    }
  }

  return (
    <Button
      tabIndex={props.tabIndex}
      ref={ref}
      className={css}
      disabled={props.disabled}
      type={props.type}
      spinning={props.spinning}
      onClick={handleClick}
      title={props.title}
    >
      {props.children}
    </Button>
  )
})

export default PrimaryButton
