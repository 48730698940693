import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import React from "react"
import { ChevronLeft } from "react-feather"
import { CompanyNotFound } from "../CompanyNotFound"
import { MenuTab } from "./Components/MenuTab"
import { CompanyActions } from "./Containers/CompanyActions"
import CompanyReflexe from "./Containers/CompanyReflexe"
import CompanyStat from "./Containers/CompanyStat"
import CompanyTeam from "./Containers/CompanyTeam"
import CompanyUsers from "./Containers/CompanyUsers"
import { CryptrContainer } from "./Containers/CryptrContainer/CryptrContainer"
import { ElearningContainer } from "./Containers/ElearningContainer/ElearningContainer"
import { InformationContainer } from "./Containers/InformationContainer/InformationContainer"
import { useCompanyPage } from "./useCompanyPage"

export const CompanyPage = () => {
  const { companyId, company, setCompany, navigate, loading, currentTab, setCurrentTab, error } = useCompanyPage()

  if (loading) {
    return <Loading /> // TODO: adapt loading to view
  }

  if (error) {
    return <CompanyNotFound id={companyId} />
  }

  return (
    <>
      <div className={"my-2 mx-1 flex justify-between rounded-xl bg-white p-4 drop-shadow"}>
        <ChevronLeft
          className={"no-fill cursor-pointer"}
          onClick={() => navigate(-1)}
        />
        <p className={"text-xl"}>{company?.name}</p>
        <div />
      </div>
      <div className={"mx-1 mt-8 h-5/6"}>
        <div className={"flex gap-1"}>
          <MenuTab
            title={"Informations"}
            active={currentTab === "information"}
            setNavigate={() => setCurrentTab("information")}
          />
          <ConditionalRender condition={company?.idp !== "auth0"}>
            <MenuTab
              title={"Cryptr"}
              active={currentTab === "cryptr"}
              setNavigate={() => setCurrentTab("cryptr")}
            />
          </ConditionalRender>
          <MenuTab
            title={"Elearnings"}
            active={currentTab === "elearning"}
            setNavigate={() => setCurrentTab("elearning")}
          />
          <MenuTab
            title={"Réflexes"}
            active={currentTab === "reflexe"}
            setNavigate={() => setCurrentTab("reflexe")}
          />
          <MenuTab
            title={"Équipes"}
            active={currentTab === "teams"}
            setNavigate={() => setCurrentTab("teams")}
          />
          <MenuTab
            title={"Collaborateurs"}
            active={currentTab === "users"}
            setNavigate={() => setCurrentTab("users")}
          />
          <MenuTab
            title={"Stat"}
            active={currentTab === "stat"}
            setNavigate={() => setCurrentTab("stat")}
          />
          <MenuTab
            title={"Actions"}
            active={currentTab === "actions"}
            setNavigate={() => setCurrentTab("actions")}
          />
        </div>
        <div className={"h-full overflow-auto rounded-bl-2xl rounded-br-2xl bg-white drop-shadow"}>
          {company && (
            <>
              {currentTab === "information" && (
                <InformationContainer
                  company={company}
                  setCompany={setCompany}
                />
              )}
              {currentTab === "cryptr" && <CryptrContainer company={company} />}
              {currentTab === "elearning" && <ElearningContainer company={company} />}
              {currentTab === "reflexe" && <CompanyReflexe company={company} />}
              {currentTab === "teams" && <CompanyTeam company={company} />}
              {currentTab === "users" && <CompanyUsers company={company} />}
              {currentTab === "stat" && <CompanyStat company={company} />}
              {currentTab === "actions" && <CompanyActions company={company} />}
            </>
          )}
        </div>
      </div>
    </>
  )
}
