export default function Checked(props: {}) {
  return (
    <svg
      className={"no-fill shrink-0"}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        width="23"
        height="23"
        rx="3"
        fill="none"
      />
      <path
        d="M8.94727 10.7733L12.6396 14.4656L24.9473 2.1579"
        stroke="#34D399"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 12V20.5556C23 21.2039 22.7425 21.8256 22.284 22.284C21.8256 22.7425 21.2039 23 20.5556 23H3.44444C2.79614 23 2.17438 22.7425 1.71596 22.284C1.25754 21.8256 1 21.2039 1 20.5556V3.44444C1 2.79614 1.25754 2.17438 1.71596 1.71596C2.17438 1.25754 2.79614 1 3.44444 1H16.8889"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
