import clsx from "clsx"
import React, { ChangeEvent, FC, ReactNode, useMemo, useState } from "react"
import { ChevronLeft, ChevronRight, Triangle } from "react-feather"
import PrimaryButton from "./../Button/PrimaryButton"
import Spinner from "./../Button/Spinner"
import Card from "./../Card/Card"
import { ConditionalRender } from "./../ConditionalRender/ConditionalRender"
import Input from "./../Input/Input"

enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

type SortProps = {
  order: SortOrder
  key: string | null
}

export type TableColumn = {
  key: string
  header: string
  disableSort?: boolean
  width?: string
}

type TableData = {
  [key: string]: ReactNode
}

type TableNavigationTranslation = {
  previous: string
  next: string
}

type TableProps = {
  className?: string
  columns: TableColumn[]
  data: TableData[]
  currentPage: number
  totalItemCount: number
  changePage: (targetedPage: number) => void
  itemsPerPage?: number
  loadMoreItems?: () => void
  header?: ReactNode
  isLoading?: boolean
  disableDefaultSearchBar?: boolean
  customFilters?: ReactNode
  disableTableBackground?: boolean
  navigationTranslation?: TableNavigationTranslation
}

export const Table: FC<TableProps> = ({
  className,
  columns,
  data,
  loadMoreItems,
  currentPage,
  itemsPerPage = 10,
  changePage,
  totalItemCount,
  header,
  isLoading = false,
  disableDefaultSearchBar,
  customFilters,
  disableTableBackground,
  navigationTranslation,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [sortProps, setSortProps] = useState<SortProps>({
    order: SortOrder.DESC,
    key: null,
  })

  const filteredData = useMemo(() => {
    return data.filter(row =>
      Object.values(row).some(value => String(value).toLowerCase().includes(searchTerm.toLowerCase()))
    )
  }, [data, searchTerm])

  const sortedData = useMemo(() => {
    if (!sortProps.key) return filteredData

    return [...filteredData].sort((a, b) => {
      const key = sortProps.key

      if (!key) {
        return 0
      }

      const valueA = a[key]
      const valueB = b[key]

      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortProps.order === SortOrder.ASC ? valueA - valueB : valueB - valueA
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return sortProps.order === SortOrder.ASC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
      } else if (typeof valueA === "number" && typeof valueB === "string") {
        return sortProps.order === SortOrder.ASC ? -1 : 1
      } else if (typeof valueA === "string" && typeof valueB === "number") {
        return sortProps.order === SortOrder.ASC ? 1 : -1
      }

      return 0
    })
  }, [filteredData, sortProps])

  const paginatedData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage
    return sortedData.slice(startIndex, startIndex + itemsPerPage)
  }, [sortedData, currentPage, itemsPerPage])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    changePage(0)
  }

  const handleSort = (key: string) => {
    setSortProps({
      key,
      order: sortProps.key === key && sortProps.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
    })
    changePage(0)
  }

  return (
    <Card
      className={clsx(
        "flex h-full flex-col justify-between gap-4",
        disableTableBackground && "bg-none hover:drop-shadow-none",
        className
      )}
    >
      <div className={"flex flex-grow flex-col gap-4"}>
        <ConditionalRender condition={customFilters !== undefined || !disableDefaultSearchBar}>
          <div
            className={
              "custom-scrollbar horizontal mb-4 flex w-full flex-col items-center justify-between overscroll-x-auto lg:flex-row"
            }
          >
            {customFilters}
            <ConditionalRender condition={!disableDefaultSearchBar}>
              <div className={"flex w-full items-center justify-center lg:justify-end"}>
                <Input
                  className={"grow last:md:grow-0"}
                  placeholder="Rechercher dans les résultats"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                {header}
              </div>
            </ConditionalRender>
          </div>
        </ConditionalRender>
        <ConditionalRender condition={isLoading}>
          <div className="mt-4 flex justify-center">
            <Spinner
              size={50}
              className={"text-primary/50"}
              hidden={!isLoading}
            />
          </div>
        </ConditionalRender>
        <ConditionalRender condition={!isLoading}>
          <div className="overflow-x-auto ">
            <table className="w-full table-fixed">
              <thead>
                <tr className={"border-b-1"}>
                  {columns.map(column => (
                    <th
                      key={column.key}
                      onClick={() => handleSort(column.key)}
                      className={clsx(
                        "font-gilroy-medium select-none text-left text-sm text-gray-400",
                        !column.disableSort && "cursor-pointer",
                        column.width ?? "w-auto"
                      )}
                    >
                      {column.header}
                      {!column.disableSort && sortProps.key === column.key && (
                        <span>
                          {sortProps.order === SortOrder.ASC ? (
                            <Triangle
                              className={"ml-1 inline"}
                              size={10}
                            />
                          ) : (
                            <Triangle
                              className={"ml-1 inline rotate-180"}
                              size={10}
                            />
                          )}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {paginatedData.map((row, index) => (
                  <tr
                    key={index}
                    className={"border-b-1 overflow-hidden whitespace-nowrap text-center hover:bg-gray-50"}
                  >
                    {columns.map(column => (
                      <td
                        key={column.key}
                        className={clsx("m-auto py-1 text-sm", column.width)}
                      >
                        {row[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ConditionalRender>
      </div>
      <div className={"flex flex-row items-center justify-evenly [&>button]:m-0"}>
        <PrimaryButton
          onClick={() => {
            changePage(Math.max(currentPage - 1, 0))
          }}
          disabled={currentPage === 0}
        >
          <span>
            <ChevronLeft className={"no-fill md:hidden"} />
            <span className={"hidden md:block"}>
              {navigationTranslation ? navigationTranslation.previous : "Previous"}
            </span>
          </span>
        </PrimaryButton>
        <span>
          {currentPage + 1} / {Math.ceil(totalItemCount / itemsPerPage)}
        </span>
        <PrimaryButton
          onClick={() => {
            changePage(currentPage + 1)
            loadMoreItems?.()
          }}
          disabled={currentPage >= Math.ceil(totalItemCount / itemsPerPage) - 1}
        >
          <span>
            <ChevronRight className={"no-fill md:hidden"} />
            <span className={"hidden md:block"}>{navigationTranslation ? navigationTranslation.next : "Next"}</span>
          </span>
        </PrimaryButton>
      </div>
    </Card>
  )
}
