import Divider from "@kamae-apps/shared/old/Component/Divider/Divider"
import React, { FC } from "react"
import { ElearningAdmin } from "../../../../Types/ELearning"
import { ElearningsList } from "./ElearningList"

export type CategoryKey = "Company" | "Home" | "RGPD"

type ElearningCategoryProps = {
  categoryKey: CategoryKey
  categoryName: string
  elearnings: ElearningAdmin[]
  check: boolean[]
  setCheck: (value: boolean[]) => void
  date: Date[]
  setDate: (value: Date[]) => void
  width: number
  setDisplayCalendar: (value: boolean) => void
  setElearningUpdateIndex: (value: number) => void
}

export const ElearningCategory: FC<ElearningCategoryProps> = ({
  categoryKey,
  categoryName,
  elearnings,
  check,
  setCheck,
  date,
  setDate,
  width,
  setElearningUpdateIndex,
  setDisplayCalendar,
}) => {
  return (
    <div className={"flex h-full w-1/3 flex-col items-center gap-4"}>
      <h2 className={"text-center text-lg"}>{categoryName}</h2>
      <Divider className={"w-1/3"} />
      <div className={"custom-scrollbar flex w-full flex-col items-center gap-4 overflow-y-auto py-2"}>
        <ElearningsList
          elearnings={elearnings}
          categoryKey={categoryKey}
          check={check}
          setCheck={setCheck}
          date={date}
          setDate={setDate}
          width={width}
          setDisplayCalendar={setDisplayCalendar}
          setElearningUpdateIndex={setElearningUpdateIndex}
        />
      </div>
    </div>
  )
}
