import { useApi } from "@kamae-apps/shared/Hooks/useApi"
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage"
import { Nullable } from "@kamae-apps/shared/Types/Nullable"
import { TEmailTemplate, TLandingPage, TScenario, TScenarioPreview } from "@kamae-apps/shared/Types/TScenario"
import PrimaryButton from "@kamae-apps/shared/old/Component/Button/PrimaryButton"
import Select from "@kamae-apps/shared/old/Component/Input/Select"
import { Table, TableColumn } from "@kamae-apps/shared/old/Component/NewTable/Table"
import ScrollText from "@kamae-apps/shared/old/Component/ScrollText/ScrollText"
import { apiRequest } from "@kamae-apps/shared/utils"
import React, { useCallback, useReducer, useState } from "react"
import { AlertCircle, Edit2, Search, Trash } from "react-feather"
import EditAddScenario from "./EditAddScenario"
import ViewScenario from "./ViewScenario"

const emptyTScenario: TScenario = {
  id: 0,
  language_id: 1,
  name: "",
  logo_file: "",
  company_id: undefined,
  landingPageHtml: "",
  emailTemplateHtml: "",
  defaultEmailSubject: "",
  defaultSender: "",
  defaultSubdomain: "",
  defaultRedirectUrl: "",
  defaultDomainId: 0,
  emailFrom: "",
  landingPageUrl: "",
}

export interface Action {
  type: "edit" | "reset" | "set"
  key?: keyof TScenario
  key1?: keyof TLandingPage
  key2?: keyof TEmailTemplate
  value?: any
}

function reducer(state: TScenario, action: Action): TScenario {
  switch (action.type) {
    case "edit":
      switch (action.key) {
        case "name":
          return { ...state, name: action.value }
        case "language_id":
          return { ...state, language_id: action.value }
        case "logo_file":
          return {
            ...state,
            logo_file: action.value === "" ? null : action.value,
          }
        case "landingPageUrl":
          return {
            ...state,
            landingPageUrl: action.value,
          }
        case "defaultRedirectUrl":
          return {
            ...state,
            defaultRedirectUrl: action.value,
          }
        case "company_id":
          return { ...state, company_id: action.value }
        case "emailFrom":
          return {
            ...state,
            emailFrom: action.value,
          }
        case "defaultEmailSubject":
          return {
            ...state,
            defaultEmailSubject: action.value,
          }
        case "landingPageHtml":
          return {
            ...state,
            landingPageHtml: action.value,
          }
        case "emailTemplateHtml":
          return {
            ...state,
            emailTemplateHtml: action.value,
          }
        default:
          throw new Error()
      }
    case "reset":
      return emptyTScenario
    case "set":
      return action.value
  }
}

export default function Scenario() {
  const [state, dispatch] = useReducer(reducer, emptyTScenario)
  const [displayEdit, setDisplayEdit] = useState(false)
  const [displayView, setDisplayView] = useState(false)
  const [scenarioId, setScenarioId] = useState<Nullable<number>>(null)
  const [type, setType] = useState<"add" | "update">("add")

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [selectedLanguage, setSelectedLanguage] = useState<DefaultLanguage>(DefaultLanguage.FR)

  const { isLoading, data, reload } = useApi<TScenarioPreview[]>("/phishing/scenarios", {
    queryParams: {
      languageId: selectedLanguage,
    },
    deps: [selectedLanguage],
  })

  const changePage = useCallback((targetedPage: number) => {
    setCurrentPage(targetedPage)
  }, [])

  const columns: TableColumn[] = [
    { key: "name", header: "Nom" },
    { key: "email_from", header: "De" },
    { key: "email_subject", header: "Sujet" },
    { key: "page_redirect_to", header: "Redirection" },
    { key: "page_url", header: "URL" },
    { key: "edit", header: "Edit", disableSort: true },
    { key: "view", header: "View", disableSort: true },
    { key: "delete", header: "Delete", disableSort: true },
  ]

  const Rows =
    data?.map(scenario => ({
      name: <ScrollText>{scenario.name}</ScrollText>,
      email_from: <ScrollText>{scenario.email_from}</ScrollText>,
      email_subject: <ScrollText>{scenario.email_subject}</ScrollText>,
      page_redirect: <ScrollText>{scenario.page_redirect}</ScrollText>,
      page_url: <ScrollText>{scenario.page_url}</ScrollText>,
      edit: (
        <Edit2
          className={"no-fill m-auto cursor-pointer"}
          onClick={() => {
            setDisplayEdit(true)
            setType("update")
            setScenarioId(scenario.id)
          }}
        />
      ),
      view: (
        <Search
          className={"no-fill m-auto flex cursor-pointer"}
          onClick={() => {
            setDisplayView(true)
            setScenarioId(scenario.id)
          }}
        />
      ),
      delete: (
        <DeleteCell
          reload={reload}
          id={scenario.id!}
        />
      ),
    })) ?? []

  const TableHeader = (
    <>
      <Select
        options={[
          { name: "Français", value: DefaultLanguage.FR },
          { name: "Anglais", value: DefaultLanguage.EN },
          { name: "Espagnol", value: DefaultLanguage.ES },
          { name: "Allemand", value: DefaultLanguage.DE },
        ]}
        className="w-full"
        defaultValue={selectedLanguage}
        placeholder={"Langue des pièges"}
        onChange={e => {
          setSelectedLanguage(parseInt(e.target.value))
          reload()
        }}
      />
      <PrimaryButton
        onClick={() => {
          setDisplayEdit(true)
          setType("add")
          dispatch({ type: "reset" })
          setScenarioId(-1)
        }}
      >
        Ajouter un scénario
      </PrimaryButton>
    </>
  )

  return (
    <div>
      <Table
        data={Rows}
        header={TableHeader}
        totalItemCount={data?.length ?? 0}
        changePage={changePage}
        itemsPerPage={10}
        currentPage={currentPage}
        columns={columns}
        isLoading={isLoading}
      />
      <EditAddScenario
        languageId={selectedLanguage}
        scenarioId={scenarioId}
        display={displayEdit}
        setDisplay={setDisplayEdit}
        reload={reload}
        type={type}
        state={state}
        dispatcher={dispatch}
      />
      <ViewScenario
        languageId={selectedLanguage}
        scenarioId={scenarioId}
        display={displayView}
        setDisplay={setDisplayView}
      />
    </div>
  )
}

function DeleteCell(props: { reload: () => void; id: number }) {
  const [confirm, setConfirm] = useState(false)
  if (!confirm) {
    return (
      <div className="flex">
        <Trash
          className={"no-fill m-auto cursor-pointer "}
          onClick={() => {
            setConfirm(true)
          }}
        />
      </div>
    )
  } else {
    return (
      <AlertCircle
        className={"no-fill m-auto text-orange-600 "}
        onClick={() => {
          apiRequest(`/phishing/scenarios/${props.id}`, { method: "DELETE" })
            .then(() => {
              props.reload()
            })
            .catch(err => {
              console.error(err)
            })
        }}
      />
    )
  }
}
