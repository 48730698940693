import PrimaryButton from "@kamae-apps/shared/old/Component/Button/PrimaryButton"
import Spinner from "@kamae-apps/shared/old/Component/Button/Spinner"
import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import Divider from "@kamae-apps/shared/old/Component/Divider/Divider"
import Input from "@kamae-apps/shared/old/Component/Input/Input"
import { errorToast } from "@kamae-apps/shared/old/Component/Toast/ToastBuilder"
import { apiRequest, formatDate } from "@kamae-apps/shared/utils"
import clsx from "clsx"
import React, { FC } from "react"
import { validateEmail } from "../../../../../../../../../reactfront/src/utils"
import Checked from "../../../../../../Icons/Checked"
import UnChecked from "../../../../../../Icons/UnChecked"
import { CompanyData } from "../../../../../Companies"
import { useSSOConnection } from "./useSSOConnection"

type SSOConnectionProps = {
  company: CompanyData
  first: boolean
}

export const SSOConnection: FC<SSOConnectionProps> = ({ company, first }) => {
  const { ssoConnectionLoading, ssoConnectionBadRequest, ssoConnection } = useSSOConnection(company)

  return (
    <>
      {!first && <div className={"border-1 border-gray-300"} />}
      <div className={"flex flex-col justify-start gap-2"}>
        <h1 className={"mb-3 w-full text-center text-xl"}>Configuration SSO</h1>
        <div className={"flex flex-col gap-3 p-3"}>
          {ssoConnectionLoading ? (
            <div className={"flex w-full justify-center"}>
              <Spinner
                className={"text-primary"}
                size={40}
              />
            </div>
          ) : (
            <>
              <ConditionalRender condition={ssoConnectionBadRequest}>
                <p className={"w-full text-center text-red-400"}>Erreur lors du chargement des données SSO 😿</p>
              </ConditionalRender>
              <ConditionalRender condition={!ssoConnectionBadRequest}>
                <>
                  <div className={"flex gap-2 py-1"}>
                    {ssoConnection ? <Checked /> : <UnChecked />}
                    <p>Une connexion SSO a été créée pour cette compagnie</p>
                  </div>
                  <div className={"flex gap-2 py-1"}>
                    {ssoConnection?.is_active ? <Checked /> : <UnChecked />}
                    <p>
                      La configuration SSO est terminée côté Cryptr
                      <br />
                      {ssoConnection?.admins && (
                        <span className={"text-sm text-gray-400"}>
                          L'invitation a été envoyée à
                          <ul className="list-disc">
                            {ssoConnection.admins.map((adminEntry, index) => (
                              <li key={`admin-${index}`}>
                                {adminEntry.email} le {new Date(adminEntry.invitationDate).toLocaleDateString()}
                              </li>
                            ))}
                          </ul>
                        </span>
                      )}
                    </p>
                  </div>
                </>
              </ConditionalRender>
            </>
          )}
        </div>
      </div>
    </>
  )
}
