import clsx from "clsx"
import React, { forwardRef, MouseEventHandler, ReactNode } from "react"

interface CardProps {
  id?: string
  children: ReactNode //content
  className?: string //add custom class
  onClick?: MouseEventHandler<HTMLDivElement>
  disabled?: boolean
}

const Card = forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref) => {
  let cssClass = clsx(
    props.className,
    "rounded-2xl bg-white p-4 hover:drop-shadow",
    props.disabled ? "grayscale [&>*]:opacity-40" : ""
  )
  return (
    <div
      id={props.id}
      className={cssClass}
      onClick={props.onClick}
      ref={ref}
    >
      {props.children}
    </div>
  )
})

export default Card
